import axios from 'axios'
import store from '../../../store/'
import errorResponseHandler from "@/api/responseError/errorResponseHandler";

const backendApi = 'https://a.lmska.com'

export function getProfileInfo(headers) {
    return new Promise((resolve, reject) => {
        axios.get(`${backendApi}/api/showProfile/`, headers)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(errorResponseHandler(error.response))
            })
    })
}

export function updateProfileInfo(meta, headers) {
    return new Promise((resolve, reject) => {
        axios.put(`${backendApi}/api/updateProfile/`, meta, headers)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(errorResponseHandler(error))
            })
    })
}

export function uploadUserAvatar(meta) {
    return new Promise((resolve, reject) => {
        axios.post(`${backendApi}/api/attachments/`, meta, store.state.credentials)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(errorResponseHandler(error))
            })
    })
}

export function unsubscribeTelegram(userId) {
	return new Promise((resolve, reject) => {
		axios.put(`${backendApi}/api/users/${userId}/reset-telegram-code`, {}, store.state.credentials)
		.then(response => {
			resolve(response)
		})
		.catch(error => {
			reject(errorResponseHandler(error))
		})
	})
}