<template>
  <div class="auth-wrapper">
    <slot/>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.btn-primary {
  background-color: #5867dd;
  border-color: #5867dd;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: #384ad7;
    border-color: #2e40d4;
  }
}
</style>