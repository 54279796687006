<template>
  <div class="kt-langBar" :class="{'show': showLanguageModal}" v-click-outside="onClickOutsideLang">

    <div class="dropdownSelector dropdown-menu-right kt-header__topbar-icon" data-toggle="dropdown" v-on:click="showLanguageModal = !showLanguageModal" >
      <img class="languageImage" :src="currLangImg" alt="" style="cursor: pointer"/>
    </div>

    <div class="dropdown-menu dropdown-menu-anim lang-bar-modal" :class="{'show': showLanguageModal}">
        <div class="kt-nav__item" v-for="item in languages" v-bind:key="item.id" v-on:click="setLocal(item)">
            <span class="kt-nav__link-icon">
              <img class="languageImage" :src="item.langImgSrc" alt=""/>
            </span>
            <span class="kt-nav__link-text" :class="{'chosen-language': curLang === item.local}">
              {{item.langName}}
            </span>
        </div>
    </div>
  </div>
</template>


<script>
import {langList} from '@/locales/langList'
import vClickOutside from 'v-click-outside'

export default {
  name: 'LangBar',

  data () {
    return {
      languages: langList,
      showLanguageModal: false
    }
  },

  computed: {
    currLangImg () {
      if (this.$store.state.curLanguage.locale === 'ru_ru') {
        return this.$store.state.url + '/assets/media/flags/ru.png'
      } else if (this.$store.state.curLanguage.locale === 'uk_ua') {
        return this.$store.state.url + '/assets/media/flags/uk.png'
      } else {
        return this.$store.state.url + '/assets/media/flags/en.svg'
      }
    },
    curLang () {
      return this.$store.state.curLanguage.locale;
    }
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  methods: {
    onClickOutsideLang () {
      this.showLanguageModal = false;
    },
    setLocal (lang) {
      this.showLanguageModal = false;
      let newLang = {
        lang: lang.local,
        img: lang.langImgSrc
      };
      this.$i18n.locale = lang.local;
      this.$bus.$emit('changeLanguage', lang.local)
      this.$store.dispatch('languageChange', newLang);
    }
  }
}
</script>

<style lang="sass">
.kt-langBar
  position: relative
  //padding: 0 0.75rem
  
.dropdown-menu
  min-width: 6rem!important

.kt-nav__item
  padding: 0 5px
  color: white
  justify-content: center
  cursor: pointer
  line-height: 3

.chosen-language
  background-color: white
  color: black

.kt-nav__link-icon
  margin: 0 10px

.kt-nav__link-text
  text-align: center
  padding: 5px
  margin: 0 auto
  border-radius: 5px

  &:hover
    background-color: white
    color: black

.languageImage
  max-width: 20px!important
  max-height: 20px !important
  border-radius: 3px
  min-width: 20px

.lang-bar-modal
  //background: linear-gradient(162deg, rgba(2,0,36,0.85) 0%, rgba(83,59,170,0.85) 0%, rgba(45,30,30,1) 100%)
  background: #5d78ff !important
  min-width: 180px !important
  right: 0 !important
  left: auto!important

@media screen and (max-width: 880px)
  .lang-bar-modal
    right: 0% !important
</style>