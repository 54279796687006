import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {i18n} from './locales/config'

import DefaultLayout from './layouts/DefaultLayout.vue'
import AuthLayout from './layouts/AuthLayout.vue'
import EmptyLayout from './layouts/EmptyLayout.vue'

import VueYouTubeEmbed from 'vue-youtube-embed'
import vueVimeoPlayer from 'vue-vimeo-player'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import ToggleButton from 'vue-js-toggle-button'
import VueSweetalert2 from 'vue-sweetalert2'
import VCalendar from 'v-calendar';
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'

import Loading from '@/components/general/Loading.vue'
Vue.component('Loading', Loading)

Vue.use(VueYouTubeEmbed)
Vue.use(vueVimeoPlayer)

Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)
Vue.component('empty-layout', EmptyLayout)

import VNetworkGraph from "v-network-graph"
import "v-network-graph/lib/style.css"
Vue.use(VNetworkGraph)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueTippy)

Vue.use(ToggleButton)

Vue.use(VueSweetalert2);

import Editor from 'v-markdown-editor'
import 'v-markdown-editor/dist/v-markdown-editor.css';
Vue.use(Editor);

import UUID from "vue-uuid";
Vue.use(UUID);

Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

import VTooltipPlugin from 'v-tooltip'
Vue.use(VTooltipPlugin)
import 'v-tooltip/dist/v-tooltip.css'

import 'vue-search-select/dist/VueSearchSelect.css'

import moment from 'moment';
Vue.prototype.$moment = moment;

Vue.config.productionTip = false

Object.defineProperty(Vue.prototype,"$bus",{
  get: function() {
    return this.$root.bus;
  }
});

new Vue({
  router,
  store,
  i18n,
  data: {
    bus: new Vue({})
  },
  render: h => h(App)
}).$mount('#app')