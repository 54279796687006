<template>
  <div style="height:100%">
    <component :is="layout">
      <router-view :key="$route.path" />
    </component>
  </div>
</template>

<script>
export default {
  beforeMount () {
    this.$i18n.locale = this.$store.state.curLanguage.locale;
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  },
}
</script>


<style>
:root {
  --bs-blue: #556ee6;
  --bs-indigo: #564ab1;
  --bs-purple: #6f42c1;
  --bs-red: #f46a6a;
  --bs-orange: #f1734f;
  --bs-yellow: #f1b44c;
  --bs-green: #34c38f;
  --bs-teal: #050505;
  --bs-cyan: #50a5f1;
  --bs-white: #fff;
  --bs-black: #000;
  --bs-gray: #74788d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #eff2f7;
  --bs-gray-300: #f6f6f6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #74788d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #556ee6;
  --bs-secondary: #74788d;
  --bs-success: #34c38f;
  --bs-info: #50a5f1;
  --bs-warning: #f1b44c;
  --bs-danger: #f46a6a;
  --bs-pink: #e83e8c;
  --bs-light: #eff2f7;
  --bs-dark: #343a40;
  --bs-primary-rgb: 85,110,230;
  --bs-secondary-rgb: 116,120,141;
  --bs-success-rgb: 52,195,143;
  --bs-info-rgb: 80,165,241;
  --bs-warning-rgb: 241,180,76;
  --bs-danger-rgb: 244,106,106;
  --bs-pink-rgb: 232,62,140;
  --bs-light-rgb: 239,242,247;
  --bs-dark-rgb: 52,58,64;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-body-color-rgb: 73,80,87;
  --bs-body-bg-rgb: 248,248,251;
  --bs-gradient: linear-gradient(180deg,hsla(0,0%,100%,0.15),hsla(0,0%,100%,0));
  --bs-body-font-size: 0.8125rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #495057;
  --bs-body-bg: #f8f8fb;
}
:root.theme--dark {
  --bs-blue: #556ee6;
  --bs-indigo: #564ab1;
  --bs-purple: #6f42c1;
  --bs-red: #f46a6a;
  --bs-orange: #f1734f;
  --bs-yellow: #f1b44c;
  --bs-green: #34c38f;
  --bs-teal: #050505;
  --bs-cyan: #50a5f1;
  --bs-white: #fff;
  --bs-black: #000;
  --bs-gray: #c3cbe4;
  --bs-gray-dark: #eff2f7;
  --bs-gray-100: #212529;
  --bs-gray-200: #2a3042;
  --bs-gray-300: #32394e;
  --bs-gray-400: #a6b0cf;
  --bs-gray-500: #bfc8e2;
  --bs-gray-600: #c3cbe4;
  --bs-gray-700: #f6f6f6;
  --bs-gray-800: #eff2f7;
  --bs-gray-900: #f8f9fa;
  --bs-primary: #556ee6;
  --bs-secondary: #c3cbe4;
  --bs-success: #34c38f;
  --bs-info: #50a5f1;
  --bs-warning: #f1b44c;
  --bs-danger: #f46a6a;
  --bs-pink: #e83e8c;
  --bs-light: #32394e;
  --bs-dark: #eff2f7;
  --bs-primary-rgb: 85,110,230;
  --bs-secondary-rgb: 195,203,228;
  --bs-success-rgb: 52,195,143;
  --bs-info-rgb: 80,165,241;
  --bs-warning-rgb: 241,180,76;
  --bs-danger-rgb: 244,106,106;
  --bs-pink-rgb: 232,62,140;
  --bs-light-rgb: 50,57,78;
  --bs-dark-rgb: 239,242,247;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-body-color-rgb: 166,176,207;
  --bs-body-bg-rgb: 34,39,54;
  --bs-gradient: linear-gradient(180deg,hsla(0,0%,100%,0.15),hsla(0,0%,100%,0));
  --bs-body-font-size: 0.8125rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #a6b0cf;
  --bs-body-bg: #222736;
}
</style>

<style lang="sass">
html
  min-height: 100%
  font-size: 14px
  
body
  min-height: 100%
  margin: 0
  padding: 0
  background-color: var(--bs-body-bg) !important
  font-size: 1rem
  line-height: 1.5
  color: var(--bs-body-color)
  font-weight: 400
  
#app
  height: 100%
  position: relative
  
main
  width: 100%
  position: relative
  
.page-layout
  padding-top: 30px
  
.page-title
  h2
    font-size: 1.15rem
    text-transform: uppercase
    font-weight: 700
    color: var(--bs-dark)
  
.kt-wizard-v2
  background: transparent
  .kt-wizard-v2__wrapper
    background: transparent
    .kt-form
      background: transparent
.kt-widget__username
  color: var(--bs-dark) !important
  
.kt-portlet
  border: 0 solid var(--bs-gray-700)
  background: var(--bs-white)
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, .03)
  color: rgba(var(--bs-dark-rgb), 1)
  .theme--dark &
    background: var(--bs-gray-200)
    border-color: var(--bs-gray-300)
  .kt-portlet__foot
    border-color: var(--bs-light)
  .kt-portlet__head
    border-color: var(--bs-light)
    .kt-portlet__head-label
      .kt-portlet__head-title
        font-size: 1.15rem
        color: var(--bs-dark)
        
.fw-500
  font-weight: 500
        
.theme--dark
  .form-control,
  .ui.selection.dropdown,
  .ui.selection.dropdown .menu > .item,
  .v-md-container,
  .v-md-container .CodeMirror
    background: #2e3446
    border-color: #32394e
    color: #bfc8e2
  .ui.selection.active.dropdown:hover,
  .ui.selection.active.dropdown:hover .menu
    border-color: var(--bs-light)
  .dropdown-menu
    background: var(--bs-gray-200)

.border,
.border-top,
.border-bottom
  border-color: var(--bs-light) !important
  
.modal-backdrop
  opacity: 0.5
.modal
  .modal-content
    .modal-header
      border-color: var(--bs-light)
      .modal-title, .close
        color: var(--bs-dark)
    .theme--dark &
      border: 0
      background-color: var(--bs-gray-200)
  
.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before
  display: none
  
.kt-badge.kt-badge--dark
  background: var(--bs-dark)
  .theme--dark &
    background: var(--bs-white)
    color: rgba(var(--bs-black-rgb),1)

.kt-datatable.kt-datatable--default
  .kt-datatable__table
    & > .kt-datatable__head,
    & > .kt-datatable__body
      & > .kt-datatable__row
        border-color: var(--bs-light)
        & > .kt-datatable__cell
          & > span
            color: var(--bs-dark)
    .theme--dark &
      background: var(--bs-gray-200)
      

@media (min-width: 1399px)
  .container
    max-width: 100%
    width: 1400px
@media (min-width: 768px) and (max-width: 1024px)
  .container
    max-width: 960px !important

@media (min-width: 576px) and (max-width: 768px)
  .container
    max-width: 100% !important

@media (min-width: 768px) and (max-width: 992px)
  .container
    max-width: 100% !important
</style>