import {getProfileInfo, updateProfileInfo} from "@/api/customer/profile/profile";
import store from "@/store";

export const showProfile = async function() {
    const token = store.getters.isLoggedIn
    let headers = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'Accept-Language': store.state.credentials.headers['Accept-Language']
        }
    }
    let response = {}
    try {
        response = await getProfileInfo(headers)
    } catch (error) {
        throw new Error(error.response.data)
    }
    return response.data
}

export const updateProfile = async function(meta) {
    const token = store.getters.isLoggedIn
    let headers = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'Accept-Language': store.state.credentials.headers['Accept-Language']
        }
    }
    let response = {}
    try {
        response = await updateProfileInfo(meta, headers)
    } catch (error) {
        throw new Error(error.response.data)
    }
    return response.data
}