import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home'),
    redirect: '/courses',
    meta: {
      requiresAuth: true
    }
  },
  // customer
  {
    path: '/ticket-block',
    name: 'TicketBlock',
    component: () => import('../views/customer/ticketBlock/TicketBlock'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/time-slot',
    name: 'TimeSlotWidget',
    component: () => import('../views/customer/timeSlot/TimeSlotWidget'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/customer/auth/LoginPage'),
    meta: {
      requiresAuth: false,
      layout: 'auth-layout'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgotPass',
    component: () => import('../views/customer/auth/ForgotPass'),
    meta: {
      requiresAuth: false,
      layout: 'auth-layout'
    }
  },
  {
    path: '/reset/:token',
    name: 'resetPassword',
    component: () => import('../views/customer/auth/ResetPassword'),
    meta: {
      requiresAuth: false,
      layout: 'auth-layout'
    }
  },
  {
    path: '/courses',
    name: 'Courses',
    component: () => import('../views/customer/courses/Courses'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/courses/:courseId',
    name: 'CoursePage',
    component: () => import('../views/customer/courses/CoursePage'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/courses/:courseId/schema',
    name: 'CoursePage',
    component: () => import('../views/customer/courses/CourseSchema'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/course/:courseId/lessons/:lessonId',
    name: 'LessonPage',
    component: () => import('../views/customer/courses/LessonPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
	{
		path: '/demo/c/:courseId/l/:lessonId',
		name: 'DemoLessonPage',
		component: () => import('../views/customer/courses/DemoLesson.vue'),
		meta: {
			requiresAuth: false,
			layout: 'auth-layout'
		}
	},
  {
    path: '/courses/:courseId/shop',
    name: 'Shop',
    component: () => import('../views/customer/shop/Shop.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/courses/:courseId/lesson/:lessonId/test/:testId',
    name: 'Test',
    component: () => import('../views/customer/courses/TestPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/courses/:courseId/lesson/:lessonId/homework/:workId',
    name: 'Homework',
    component: () => import('../views/customer/courses/HomeworkPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/customer/orders/Orders'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/upgrade/:orderId&:eventId&:lang',
    name: 'Upgrade',
    component: () => import('../views/customer/orders/OrderUpgrade'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('../views/customer/chat/Chat'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-profile',
    name: 'GlobalProfile',
    component: () => import('../views/customer/profile/GlobalProfile'),
    meta: {
      requiresAuth: true
    }
  },
	{
		path: '/user-public',
		name: 'PublicProfileConstructor',
		component: () => import('../views/customer/profile/ProfileConstructor.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/user-public/:uuid',
		name: 'PublicProfile',
		component: () => import('../views/customer/profile/PublicPage.vue'),
		meta: {
			requiresAuth: false
		}
	},
  // {
  //   path: '/profile',
  //   name: 'Profile',
  //   component: () => import('../views/customer/profile/Profile'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/profile-public',
  //   name: 'ProfilePublic',
  //   component: () => import('../views/customer/profile/ProfilePublic'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/materials/:eventId/:orderId/:ticketId',
    name: 'Materials',
    component: () => import('../views/customer/materials/Materials'),
    meta: {
      requiresAuth: true
    }
  },
  // registration
  {
    path: '/add-order',
    name: 'AddOrder',
    component: () => import('../views/registration/registration/AddOrder'),
    meta: {
      requiresAuth: false,
      layout: 'empty-layout'
    }
  },
	{
		path: '/p/:cryptoHash',
		name: 'AddOrderWithHash',
		component: () => import('../views/registration/registration/AddOrder'),
		meta: {
			requiresAuth: false,
			layout: 'empty-layout'
		}
	},
  {
    path: '/oferta/:id',
    name: 'Oferta',
    component: () => import('../views/registration/registration/Oferta'),
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  // checkout
  {
    path: '/course/:courseId/pay-order/:orderHash/restore',
    name: 'RestoreOrder',
    component: () => import('../views/registration/checkout/RestoreOrder'),
    props: true,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/course/:courseId/pay-order/:orderHash',
    name: 'PayOrder',
    component: () => import('../views/registration/checkout/PayOrder'),
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  // thank you page
  {
    path: '/thank-you-page',
    name: 'ThankYouPage',
    component: () => import('../views/registration/tnankyoupage/ThankYouPage'),
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  //print
  {
    path: '/print-servers/:token',
    name: 'PrintServers',
    component: () => import('../views/customer/printServers/PrintServers.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isLoggedIn;
  if (to.meta.requiresAuth && to.name !== 'Login' && !isAuthenticated) {
    sessionStorage.setItem('redirect', window.location.href)
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router